import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  /**
   * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
   */
  {
    pid: 0,
    lpSymbol: 'Flamez',
    lpAddresses: {
      97: '0x6e2c1dbAd941dC69457264ABa9110Ef7D2553679',
      56: '0x63c618917E5632367f9f9f9D7F11E596d414a731',
    },
    token: tokens.cake,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 1,
    lpSymbol: 'Flamez-BNB LP',
    lpAddresses: {
      97: '0xAE28E738F13344959251752b1091A2369A864aCD',
      56: '0x63c618917E5632367f9f9f9D7F11E596d414a731',
    },
    token: tokens.cake,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 2,
    lpSymbol: 'BUSD-BNB LP',
    lpAddresses: {
      97: '0xccb8723bd266b02B0F8D59a79BEFc527C7c56918',
      56: '0x63c618917E5632367f9f9f9D7F11E596d414a731',
    },
    token: tokens.busd,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 3,
    lpSymbol: 'Flamez-BUSD LP',
    lpAddresses: {
      97: '0x5B337bA2FB8F3Bd98836FfE0178E21849b372D77',
      56: '0x63c618917E5632367f9f9f9D7F11E596d414a731',
    },
    token: tokens.cake,
    quoteToken: tokens.busd,
  }
]

export default farms
